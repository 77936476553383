import { useEffect, useState } from "react";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import { Image } from "lib/imgproxy";
import { cx } from "class-variance-authority";
import { KeyTextField } from "@prismicio/types";

type Feature = {
    title: string;
    description: string;
    imgAlt: string;
    imgUrl: string;
};

/**
 * Props for `FeaturesSlice`.
 */
export type FeaturesSliceProps =
    SliceComponentProps<Content.FeaturesSliceSlice>;

/**
 * Component for "FeaturesSlice" Slices.
 */
const FeaturesSlice = ({ slice }: FeaturesSliceProps): JSX.Element => {
    const [active, setActive] = useState<string>("");
    const featuresObject = slice.primary.features.reduce(
        (acc, val) => {
            const { name, title, description, illustration } = val;
            if (name) {
                acc[name] = {
                    title: keyTextFieldToString(title),
                    description: keyTextFieldToString(description),
                    imgAlt: illustration.alt ?? "",
                    imgUrl: illustration.url ?? "",
                };
            }
            return acc;
        },
        {} as Record<string, Feature>,
    );
    const activeFeature = featuresObject[active];

    useEffect(() => {
        const feature = slice.primary.features[0];
        if (feature) setActive(feature.name ?? "");
    }, [slice]);

    if (!activeFeature) return <div />;
    return (
        <section
            data-slice-type={slice.slice_type}
            data-slice-variation={slice.variation}
            className="flex flex-col items-center gap-[60px]"
        >
            <div className="flex flex-col gap-5 text-center">
                <div className="typography-h2 lg:typography-h1 font-semibold text-blue-grey-900">
                    {slice.primary.title}
                </div>
                <div className="typography-main mx-auto text-blue-grey lg:max-w-[64%]">
                    {slice.primary.description}
                </div>
            </div>
            <div className="flex flex-col items-center gap-6 lg:gap-[60px]">
                <div className="flex gap-6 lg:gap-14">
                    {slice.primary.features.map((f) => (
                        <div
                            key={f.name}
                            className="flex cursor-pointer flex-col items-center gap-4 transition-all duration-300 ease-in-out lg:flex-row"
                            onClick={() => setActive(f.name ?? "")} // Set active when clicked
                        >
                            <div className="relative size-12 lg:size-8">
                                <Image
                                    alt={f.icon.alt ?? ""}
                                    src={f.icon.url ?? ""}
                                    objectFit="contain"
                                    layout="fill"
                                    unoptimized
                                    sizes="120px"
                                />
                            </div>
                            <div className="flex flex-col gap-1">
                                <div className="typography-h4 lg:typography-h3 font-medium text-blue-grey hover:font-bold">
                                    {f.name}
                                </div>
                                <div
                                    className={cx(
                                        keyTextFieldToString(f.name) === active
                                            ? "bg-primary-200"
                                            : "bg-transparent",
                                        "h-1 w-full rounded-xl",
                                    )}
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-col items-center gap-5 py-2.5 lg:flex-row lg:gap-[70px]">
                    <div className="flex flex-col gap-2 text-center lg:gap-5 lg:text-start">
                        <div className="typography-h3 lg:typography-h2 font-semibold text-blue-grey-900">
                            {activeFeature.title}
                        </div>
                        <div className="typography-main text-blue-grey">
                            {activeFeature.description}
                        </div>
                    </div>
                    <div className="group relative aspect-[1.366] h-fit w-full lg:h-[470px]">
                        <Image
                            alt={activeFeature.imgAlt}
                            src={activeFeature.imgUrl}
                            layout="fill"
                            objectFit="contain"
                            unoptimized
                            sizes="(max-width: 576px) 550px, 700px"
                            className="transition-transform duration-300 ease-in-out group-hover:scale-105" // Adjust classes as needed
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

const keyTextFieldToString = (v: KeyTextField): string => v ?? "";

export default FeaturesSlice;
